<template>
  <div class="opertion_wrap">
    <div class="head">
      <div class="left">
        题目ID:{{question.question_id}}
        <!-- {{$rands(question.question_id)}} -->
      </div>
      <div class="right">
        <span class="question_score">
          题目分数：{{question.question_score}}
        </span>
        <span class="my_score">
          我的得分: <span style="color:#FF6147">{{question.user_score}}</span>
        </span>
        <span class="img_warp">
          <img src="@/assets/self_correct/发帖.png"
               @click="addDiscuss"
               alt=""
               v-if="userInfo.show_discuss == 1">
          <img src="@/assets/self_correct/纠错.png"
               @click="feed"
               alt="">
          <img src="@/assets/self_correct/收藏.png"
               v-if="!question.has_favorite"
               @click="collect"
               alt="">
          <img src="@/assets/self_correct/已收藏.png"
               v-else
               @click="cancelCollect"
               alt="">
        </span>
      </div>
    </div>
    <div class="content"
         :style="{'height':`calc(100% - ${opertion_heigth?360:100}rem)`}">
      <div class="coll_wrap">
        <el-collapse v-model="activeNames"
                     @change="handleChange">

          <el-collapse-item title="题目内容"
                            v-if="$route.path == '/self_correct'"
                            name="1">
            <div v-if="question.question_head_content"
                 class="topic"
                 v-html="question.question_head_content"></div>
            <div class="topic"
                 v-html="question.question_content"></div>
          </el-collapse-item>
          <el-collapse-item title="题目解析"
                            name="2">
            <div class="topic"
                 v-html="question.question_explain"></div>
          </el-collapse-item>
          <el-collapse-item title="题目答案"
                            name="3">
            <div class="topic"
                 v-html="question.question_answer"></div>
          </el-collapse-item>
          <el-collapse-item title="我的答案"
                            name="4">
            <div class="topic"
                 v-html="question.user_answer"></div>
          </el-collapse-item>
          <el-collapse-item title="我的评语"
                            v-if="$route.path == '/analysis'"
                            name="5">
            <div>
              {{question.comment_to_user}}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="fixed_btn"
         v-if="opertion_heigth"
         :style="{'height':`${opertion_heigth}rem`}">
      <div class="line">
        <div class="label">
          得分
        </div>
        <div class="input_wrap">
          <el-input-number v-model="question.user_score"
                           :min="0"
                           :disabled="question.auto_judge == 1"
                           :max="Number(question.question_score)"
                           label="描述文字"></el-input-number>
        </div>
      </div>
      <div class="line">
        <div class="label">
          自评
        </div>
        <div class="input_wrap">
          <el-input style="width:calc(100% - 100rem);placeholder:请输入评价"
                    :disabled="question.auto_judge == 1"
                    v-model="question.comment_to_user" />
        </div>
      </div>
      <div class="line">
        <div class="label">
          <img src="@/assets/self_correct/上一题.png"
               @click="change(-1)"
               alt="">
        </div>
        <div class="input_wrap">
          <div class="btn_wrap">
            <div class="btn"
                 @click="change(1)">
              下一题
            </div>
            <div class="btn"
                 @click="submitAll">
              提交整个试卷
            </div>
          </div>
        </div>
      </div>
    </div>
    <Form ref="FormRef" />
    <FeedBack ref="FeedBackRef" />
    <Tag ref="TagRef" />
  </div>
</template>

<script>
import Form from '@/views/discuss/components/form.vue'
import Tag from './tag.vue'
import { getBoard } from '@/api/discuss.js'
import { delCollect } from '@/api/errorbasis.js'
import FeedBack from './feedback.vue'
export default {
  data () {
    return {
      num: 0,
      activeNames: ['1', '2', '3'],
      boardList: [],
      userInfo: {}
    }
  },
  props: {
    opertion_heigth: {
      type: Number,
      default: 240
    },
    question: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    Form, FeedBack, Tag
  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  },
  methods: {
    feed () {
      let form = {
        category: 0,
        comment: '',
        question_id: this.question.question_id,
        user_paper_detail_id: this.question.user_paper_detail_id
      }
      this.$refs.FeedBackRef.form = form
      this.$refs.FeedBackRef.dialogVisible = true
    },
    handleChange () { },
    change (index) {
      this.$parent.change(index)
    },
    submitAll () {
      this.$parent.submitAll()
    },
    // 空方法 迁移过来的子组件触发使用
    initList () { },
    async addDiscuss () {
      if (this.boardList.length == 0) {
        const { data } = await getBoard()
        this.boardList = data
      }
      let form = {
        board_id: '',
        discuss_title: '',
        discuss_content: '',
        // question_id: this.$rands(this.question.question_id),
        question_id: this.question.question_id,

      }
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.form = form
    },
    collect () {
      let form = {
        question_id: this.question.question_id,
        tags: ''
      }
      this.$refs.TagRef.dialogVisible = true
      this.$refs.TagRef.form = form
    },
    async cancelCollect () {
      this.$confirm("确定取消收藏这道题目？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await delCollect({
          id: this.question.question_favorite_id
        })
        this.$message.success('取消收藏成功')
        this.question.has_favorite = 0
      }).catch(e => e)
    }
  }
}
</script>

<style lang="scss" scoped>
.opertion_wrap {
  position: relative;
  height: 100%;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68rem;
    padding: 0 30rem;
    background: #eff8ff;
    flex-wrap: wrap;
    .left {
      text-indent: 30rem;
      font-size: 20rem;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
      height: 36rem;
    }
    .right {
      flex: 1;
      display: flex;
      min-width: 310px;
      align-items: center;
      justify-content: flex-end;
      .question_score {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        margin-right: 30rem;
      }
      .my_score {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        margin-right: 30rem;
      }
      img {
        width: 36rem;
        height: 36rem;
        margin-right: 30rem;
      }
    }
  }
  .content {
    width: calc(100% - 60rem);
    overflow: auto;
    background: #ffffff;
    margin-left: 30rem;
    .coll_wrap {
      margin-top: 30rem;
    }
  }
  .fixed_btn {
    position: absolute;
    width: 100%;
    background: #ffffff;
    box-shadow: 0rem 2rem 10rem 4rem rgba(0, 0, 0, 0.05);
    border: 1rem solid #d9d9d9;
    bottom: 0;
    .line {
      margin-top: 20rem;
      display: flex;
      .label {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        text-align: right;
        height: 50rem;
        line-height: 50rem;
        margin-left: 50rem;
        padding-right: 15rem;
        cursor: pointer;
        img {
          width: 50rem;
          height: 50rem;
        }
      }
      .input_wrap {
        flex: 1;
        .btn_wrap {
          display: flex;
          margin-right: 100rem;
          .btn {
            flex: 1;
            height: 50rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-size: 20rem;
            font-weight: bold;
            background: #fe765f;
            border-radius: 25rem;
            cursor: pointer;
            &:nth-child(1) {
              margin-left: 30rem;
              margin-right: 30rem;
              background: #3f9eff !important;
            }
          }
        }
      }
    }

    ::v-deep .el-input--medium .el-input__inner {
      height: 50rem;
      line-height: 50rem;
    }
    ::v-deep .el-input-number__decrease,
    .el-input-number__increase {
      height: 47rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ::v-deep .el-input-number__increase {
      height: 47rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

::v-deep .el-collapse-item__header {
  background: #f6f6f6;
  border-radius: 10rem;
  font-size: 20rem;
  font-weight: 500;
  color: #333333;
  text-indent: 20rem;
  margin-top: 20rem;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: none;
}
::v-deep .el-collapse {
  border: none;
}
::v-deep .el-collapse-item__content {
  margin-left: 20rem;
  margin-top: 20rem;
}
::v-deep .el-collapse-item__arrow.is-active {
  margin-top: -20rem;
}
.topic {
  font-size: 20rem;
}
</style>