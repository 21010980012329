<template>
  <div>
    <el-dialog title="收藏"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500rem"
               :before-close="handleClose">
      <el-row :gutter="10"
              style="margin-bottom:20rem">
        <el-col :span="24"
                class="center_col">
          <span>标签:</span>
          <el-select style="width:85%"
                     v-model="form.question_favorite_type_ids"
                     multiple
                     placeholder="请选择">
            <el-option v-for="item in tagList"
                       :key="item.question_favorite_type_id"
                       :label="item.type_name"
                       :value="item.question_favorite_type_id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24"
                class="center_col">
          <span>笔记:</span>
          <el-input clearable
                    placeholder="请输入"
                    type="textarea"
                    v-model="form.tags"
                    style="width:85%"
                    :rows="4" />
        </el-col>
      </el-row>
      <div class="btn_warp">
        <div @click="handleClose">
          取消
        </div>
        <div @click="save">
          确定
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { collect, getTag } from '@/api/error.js'
export default {
  data () {
    return {
      form: {},
      dialogVisible: false,
      tagList: []
    }
  },
  mounted () {
    this.getTagList()
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    async getTagList () {
      const { data } = await getTag()
      this.tagList = data.list

    },
    async save () {
      const { data } = await collect(this.form)
      this.handleClose()
      this.$parent.question.has_favorite = 1
      this.$parent.question.question_favorite_id = data.question_favorite_id
      this.$message.success('收藏成功，可前往错题本查看')
    }
  }
}
</script>

<style lang="scss" scoped>
.center_col {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-right: 10rem;
    font-size: 18rem;
    font-weight: bold;
  }
}
.el-row {
  &:nth-child(1) {
    display: flex;
    justify-content: center;
    margin-bottom: 10rem;
  }
}

::v-deep .el-dialog__header {
  font-size: 20rem;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4rem;
}

::v-deep .el-dialog__close {
  display: none;
}

.btn_warp {
  height: 50rem;
  border-top: 1rem solid #e5e5e5;
  display: flex;
  width: 554rem;
  margin-left: -20rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      font-size: 18rem;
      font-weight: bold;
      color: #999999;
      border-right: 1rem solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 18rem;
      font-weight: bold;
      color: #2196f3;
    }
  }
}

::v-deep .el-dialog {
  border-radius: 20rem;
  min-height: 350rem;
  margin-top: 198rem !important;
}
</style>