<template>
  <div>
    <el-dialog title="反馈"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="554rem"
               :before-close="handleClose">
      <div class="block">
        <span :class="form.category == 0?'isActive':''"
              @click="checkType(0)">
          题目反馈
        </span>
        <span :class="form.category == 1?'isActive':''"
              @click="checkType(1)">
          批改反馈
        </span>
      </div>
      <el-form :model="form"
               label-width="56rem">
        <el-form-item label="反馈:">
          <el-input placeholder="请输入"
                    type="textarea"
                    :rows="7"
                    style="width:90%"
                    v-model="form.comment" />
        </el-form-item>
        <el-row>
          <el-form-item label-width="0rem"
                        style="margin-bottom:10rem">
            <span class="desction">
              注：题目有错误或者批改有错误，反馈内容才有效
            </span>
          </el-form-item>
        </el-row>
      </el-form>
      <div class="btn_warp">
        <div @click="handleClose">
          取消
        </div>
        <div @click="save">
          确定
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { errorCorrect } from '@/api/error.js'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {}
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    async save () {
      if (this.form.comment == '') {
        this.$message.warning('请输入反馈内容')
        return
      }
      let form = JSON.parse(JSON.stringify(this.form))
      if (form.category == 0) {
        delete form.user_paper_detail_id
      }
      await errorCorrect(form)
      this.$message.success('反馈成功')
      this.handleClose()
    },
    checkType (num) {
      this.form.category = num
    }
  }
}
</script>

<style lang="scss" scoped>
.btn_warp {
  height: 50rem;
  border-top: 1rem solid #e5e5e5;
  display: flex;
  width: 554rem;
  margin-left: -20rem;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      font-size: 18rem;
      font-weight: bold;
      color: #999999;
      border-right: 1rem solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 18rem;
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 5rem 20rem;
}

::v-deep .el-dialog__header {
  font-size: 18rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog {
  border-radius: 10rem;
  margin-top: 23vh !important;
}

::v-deep .el-form-item__label {
  color: #333333;
  font-size: 18rem;
  font-weight: 500;
}

.block {
  display: flex;
  margin-bottom: 20rem;
  span {
    padding: 7rem 18rem;
    font-size: 16rem;
    font-weight: bold;
    color: #666666;
    border-radius: 5rem;
    background: #f6f6f6;
    &:nth-child(2) {
      margin-left: 20rem;
    }
  }
}
.isActive {
  background: #007af0 !important;
  color: white !important;
}

::v-deep .el-dialog__close {
  display: none;
}
::v-deep .el-dialog {
}
.desction {
  font-size: 16rem;
  font-weight: bold;
  color: #ff6147;
}
</style>